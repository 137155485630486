import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

const { dsn, environment, release, user } = window.SENTRY_CONFIG;

Sentry.init({
    dsn,
    environment,
    release,

    // beforeSend(event, hint) {
    //     if (event.exception) {
    //         Sentry.showReportDialog({ eventId: event.event_id });
    //     }
    //
    //     return event;
    // },

    // integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],

    // tracesSampleRate: 0.2,
    // replaysSessionSampleRate: 0.0,
    // replaysOnErrorSampleRate: 1.0
});

Sentry.setUser(user);
