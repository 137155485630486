import "./formulaire-diagnostics.scss";

$('.recherche-diagnostics .champ-commune-bien').webfAutocomplete({
    source: Routing.generate('app_site_default_rechercheville'),
    onSelect: function ($item, suggest) {
        let $input = $(this);

        let $codePostal = $('.champ-code-postal-bien');

        $input.webfAutocomplete('close');

        $codePostal.val(suggest.value);
        $input.val(suggest.nomVille);

        return false;
    },
});

$('input, select').on('change', function () {
    let value = $(this).val();

    if (value.length) {
        $(this).addClass('with-value')
    } else {
        $(this).removeClass('with-value')
    }
});

$('.recherche-diagnostics').webfAjaxform({
    beforeSubmit: function (data) {
        $('.dialog-wait').webfDialog('open');
    },
    onSuccess: function (data) {
        $('.dialog-wait').webfDialog('close');

        $('.errors-form-diagnostics').remove();

        if ('error' == data.status) {
            let $errors = $('<div>').addClass('alert alert-warning errors-form-diagnostics'),
                $ulErrors = $('<ul>');

            webf.each(data.errors.global, function (i, error) {
                let $li = $('<li>').html(error);
                $ulErrors.append($li);
            });

            webf.each(data.errors.fields, function (key, error) {
                let $li = $('<li>').html(error),
                    $input = $('input[name="diagnostics_obligatoires[' + key + ']"], select[name="diagnostics_obligatoires[' + key + ']"]'),
                    $label = $input.data('label-error');

                $li.prepend('<strong>' + $label + '</strong> : ');
                $ulErrors.append($li);
            });

            $errors.append($ulErrors);

            $('.recherche-diagnostics').prepend($errors);
        } else {
            window.location.href = Routing.generate('app_site_default_resultat')
        }
    }
});
