import $ from 'jquery';
import refreshWebf from '../smart/refresh_webf.js'

refreshWebf();

$('.flash-messages').each(function () {
    var type = $(this).data('type');

    $(this).webfAlert({
        alertType: type,
        autoClose: true,
        delayClose: 5000,
        durationClose: 1000,
    });
});

$('.form-contact-footer').webfAjaxform({
    onSuccess: function (data) {
        $('.form-contact-footer-errors')
            .addClass('d-none')
            .html('');

        if ('OK' === data.status) {
            window.location.href = data.redirectUrl;
        } else if ('ERROR' === data.status) {
            let errors = [];

            webf.each(data.errors.fields, function (key, value) {
                if (value.length) {
                    errors.push(value);
                }
            });

            webf.each(data.errors.global, function (key, value) {
                if (value.length) {
                    errors.push(value);
                }
            });

            if (errors.length) {
                $('.form-contact-footer-errors')
                    .removeClass('d-none')
                    .html(errors.join('<br>'));
            }
        }
    },
});

$('.form-inscription-newsletter').webfAjaxform({
    beforeSend: function () {
        $('.dialog-wait').webfDialog('open');
    },
    onSuccess: function (data) {
        $('.dialog-wait').webfDialog('close');

        $('.form-inscription-newsletter-errors')
            .addClass('d-none')
            .html('');

        if ('OK' === data.status) {
            $('.champ-email-inscription-newsletter').val('');

            $('<div>Un email vous a été envoyé pour valider votre inscription.</div>').webfAlert({
                alertType: 'success',
                placement: 'center',
                autoClose: true,
                delayClose: 5000,
                durationClose: 1000,
            });
        } else if ('ERROR' === data.status) {
            let errors = [];

            webf.each(data.errors.fields, function (key, value) {
                if (value.length) {
                    errors.push(value);
                }
            });

            webf.each(data.errors.global, function (key, value) {
                if (value.length) {
                    errors.push(value);
                }
            });

            if (errors.length) {
                $('.form-inscription-newsletter-errors')
                    .removeClass('d-none')
                    .html(errors.join('<br>'));
            }
        }
    },
});

if( navigator.userAgent.match(/iphone/i) ) {
    $("a[href^='tel:']").each(function() {
        $(this).prop('href', $(this).prop('href').replace(/^tel:/, 'callto:'));
    } );
}
